<template>
	<div class="mt-10">
		<form @submit.prevent="submit">
			<section id="general">
				<h3>Generellt</h3>

				<div class="grid grid-cols-3 gap-5">
					<BaseInput type="text" v-model="user.ssn" readonly>
						Personnummer
					</BaseInput>

					<BaseInput type="text" v-model="user.first_name" autocomplete="off">
						Förnamn
					</BaseInput>

					<BaseInput type="text" v-model="user.last_name" autocomplete="off">
						Efternamn
					</BaseInput>

					<BaseInput type="email" v-model="user.email" autocomplete="off">
						E-postadress
					</BaseInput>

					<BaseInput type="phone" v-model="user.telephone" autocomplete="off">
						Telefonnummer
					</BaseInput>
				</div>
			</section>

			<section id="clubs">
				<h3>Mina klubbar</h3>

				<div class="grid grid-cols-3 gap-5">
					<div class="col-span-2">
						<BaseInput type="search" v-model="searchClubText">
							Sök klubb
						</BaseInput>
					</div>
				</div>

				<div class="flex gap-5 items-center mt-5">
					<BaseChip v-for="(club, $club_idx) in clubs" :key="club.id" @remove="removeClub($club_idx)">
						{{ club.alias }}
					</BaseChip>
				</div>
			</section>

			<section id="change-password">
				<h3>Byta lösenord</h3>

				<div class="grid grid-cols-3 gap-5">

					<BaseInput type="password" v-model="passwords.current_password" autocomplete="off">
						Nuvarande lösenord
					</BaseInput>

					<div></div>
					<div></div>

					<BaseInput type="password" v-model="passwords.new_password" autocomplete="off">
						Nytt lösenord
					</BaseInput>

					<BaseInput type="password" v-model="passwords.confirm_new_password" autocomplete="off">
						Verifiera lösenord
					</BaseInput>
				</div>
			</section>

			<div class="px-10">
				<BaseButton type="submit">
					Spara
				</BaseButton>
			</div>
		</form>
	</div>
</template>

<script>
export default {
	name: 'ProfileGeneral',
	data: () => ({
		user: null,
		clubs: [
			{id: 1, alias: 'NÄRK'},
			{id: 2, alias: 'TFRK'},
		],
		passwords: {
			current_password: '',
			new_password: '',
			confirm_new_password: '',
		},

		searchClubText: '',
	}),
	methods: {
		removeClub(idx) {
			this.clubs.splice(idx, 1)
		},
		submit() {
			console.log('Submit')
		},
	},
	created() {
		this.user = {...this.$store.getters['AuthStore/user']}
	},
}
</script>

<style scoped>
section {
	@apply py-10;
}
</style>
